<!--
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-08-25 19:42:12
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-08-26 23:06:53
 * @FilePath: \uova\src\views\home\meishuguan\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div style="width: 100%; height: 100%">
    <img style="width: 100%; height: auto%" src="../../../assets/中秋进场_5_1.gif" alt="" />
  </div>
</template>

<script>
export default {
  name: 'UovaIndex',

  data() {
    return {};
  },

  mounted() {
    setTimeout(() => {
      window.location = 'http://resources.msg.uovaverse.com/';
    }, 3500);
  },

  methods: {},
};
</script>

<style lang="scss" scoped></style>
