<!--
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-08-25 19:41:42
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-08-25 19:45:05
 * @FilePath: \uova\src\views\home\wuchangshuo\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div></div>
</template>

<script>
export default {
  name: 'UovaIndex',

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
