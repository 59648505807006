<!--
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-08-24 18:15:09
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-08-27 00:18:34
 * @FilePath: \uova\src\views\home\first\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-08-15 09:40:18
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-08-25 19:43:22
 * @FilePath: \mid-autumn\src\views\home\first\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="index">
    <div class="indexlist">
      <!-- <img style="width: 100%; height: auto; margin-top: 0%" src="../../../assets/吴昌硕.gif" alt="" /> -->
    </div>
    <!-- <div class="wuchangshuo" @click="$router.push('/home/wuchangshuo')"></div> -->
    <div class="zhongqiu" @click="jumpmeishuguan()"></div>
    <!-- <img
      style="width: 100px; height: 100px; position: fixed; left: 35%; bottom: 0%; z-index: 999"
      v-if="show"
      src="../../../assets/guangquan.png"
      alt=""
    /> -->
    <a class="wuchangshuo" href="http://106.14.211.64:81"></a>
    <!-- <img
      style="width: 100px; height: 100px; position: fixed; left: 40%; top: 11%; z-index: 999"
      v-if="show"
      src="../../../assets/guangquan.png"
      alt=""
    /> -->
    <!-- <a class="zhongqiu" href="http://uova-act.zeroisle.com"></a> -->
    <a class="longgong" href="http://106.14.211.64:82"></a>
  </div>
</template>

<script>
import { Notify } from 'vant';
export default {
  data() {
    return {
      countList: 0,
      show: false,
    };
  },
  methods: {
    jumpmeishuguan() {
      this.show = true;
      setTimeout(() => {
        this.$router.push('/home/meishuguan');
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.index {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  /* background-image: url('../../../assets/吴昌硕.gif');
  background-size: 100% auto;
  background-position: center center; */
  .indexlist {
    width: 100%;
    height: 100%;
    background-image: url('../../../assets/吴昌硕.gif');
    background-position: 2px -88px;
    background-size: 100% auto;
    /* background-position: center center; */
  }
  .zhongqiu {
    width: 100px;
    height: 100px;
    position: fixed;
    left: 38%;
    bottom: 4%;
    z-index: 999;
    a {
      width: 100%;
      height: 100%;
    }
  }
  /* .zhongqiuimg {
    width: 200px;
    height: 200px;
    position: fixed;
    left: 38%;
    bottom: 4%;
    z-index: 999;
  } */
  .wuchangshuo {
    width: 100px;
    height: 100px;
    position: fixed;
    left: 40%;
    top: 11%;
    z-index: 999;
    a {
      width: 100%;
      height: 100%;
    }
  }
  .longgong {
    width: 100px;
    height: 100px;
    position: fixed;
    right: 14%;
    bottom: 24%;
    z-index: 999;
    a {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
